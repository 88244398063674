import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/application/composables/modal.js'
import { DateTime } from 'luxon'
import { useExtractRecipients } from '@/application/composables/extractRecipients.js'
import { store } from '@/application/store'
import { getLocalizedServerError } from '@/application/utils/localization'

export function useSubmitMessage(license, request, successMessage, isBulk = false, initialFields, bulkLimit) {
  const { extractRecipients } = useExtractRecipients()
  const isDisabled = ref(false)
  const i18n = useI18n()
  const { openModal } = useModal()
  const formatFormData = (data) => {
    if (isBulk) {
      const isSchedule = data.bulk_schedule
      const deleteTimeLimitations = !data.bulk_from_date || data.bulk_to_time === 'Invalid DateTime'
      if (!deleteTimeLimitations) {
        const fromTime = DateTime.fromISO(`${data.bulk_from_date}T${data.bulk_from_time}:00`, { zone: data.bulk_time_zone })
        const toTime = DateTime.fromISO(`${data.bulk_from_date}T${data.bulk_to_time}:00`, { zone: data.bulk_time_zone })
        data.bulk_from_time = fromTime.toString()
        data.bulk_to_time = toTime.toString()
      } else {
        delete data.bulk_from_time
        delete data.bulk_to_time
        delete data.bulk_from_date
      }

      try {
        data.bulk_recipients = extractRecipients(data.bulk_recipients)
      } catch {
        data.bulk_recipients = Array.from(data.bulk_recipients)
      }

      if (isBulk && bulkLimit !== undefined) {
        data.bulk_recipients = data.bulk_recipients.slice(0, bulkLimit)
      }

      const only_for_schedule = [
        'bulk_from_time', 'bulk_to_time',
        'bulk_from_date', 'bulk_sending_days',
        'bulk_time_zone',
      ]
      const obj = {}
      Object.keys(data).forEach(key => {
        let needSendServer = true
        if (!isSchedule) {
          needSendServer = !only_for_schedule.includes(key)
        }
        if (needSendServer) {
          obj[key.replace('bulk_', '')] = data[key]
        }
      })
      if (obj.schedule) delete obj.schedule
      if (obj.agreement) delete obj.agreement
      if (!obj.sender_name) delete obj.sender_name
      return obj
    } else {
      if (!data.sender_name) delete data.sender_name
      data.type = data.marketing !== true ? 'transactional' : 'marketing'
      delete data.agreement
      delete data.marketing
      return data
    }
  }
  const submit = (data, { resetForm, setValues }) => {
    const dataFromStore = isBulk ? store.getters.getBulkFormCache(license) : store.getters.getSingleFormCache(license)
    const formData = JSON.parse(JSON.stringify(dataFromStore))

    isDisabled.value = true
    request(formatFormData(formData), {
      Authorization: license.license_key,
      'Loop-Secret-Key': license.secret_api_key,
    })
      .then(res => {
        isDisabled.value = false
        openModal(i18n.t('success'), i18n.t(successMessage, { count: res.data.count }))
        store.dispatch('clearFormData', { license, type: isBulk ? 'BULK' : 'SINGLE' })
        resetForm()
        setValues(initialFields)
      })
      .catch(err => {
        isDisabled.value = false
        openModal(i18n.t('error'), err.response ? getLocalizedServerError(i18n, 'forms.submitMessage.errors.', err.response) : i18n.t('error'))
      })
  }
  return {
    submit,
    isDisabled,
  }
}
