export const BulkStatuses = {
  disabled: 'DISABLED',
  requested: 'REQUESTED',
  premoderation: 'PREMODERATION',
  enabled: 'ENABLED',
}

export const canSendBulk = (license) => {
  return license.sender_names && license.sender_names.length > 0
}
