import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { sendSingleMessage } from '@/application/services/message.js'
import { useSubmitMessage } from '@/application/composables/submitMessage.js'
import { useGenerateSenderName } from '@/application/composables/generateSenderName.js'
import { BulkStatuses } from '@/application/utils/license'
import { store } from '@/application/store'

export function useSingleMessageCreate(license) {
  const i18n = useI18n()
  const { generateSenderName } = useGenerateSenderName()
  const senderNames = license.sender_names
  const rememberedData = store.getters.getSingleFormCache(license)

  const cleanFieldValue = {
    agreement: true,
    sandbox: false,
    sender_name: senderNames && senderNames.length === 1 ? senderNames[0] : null,
    marketing: false,
  }

  const initialFieldValue = {
    ...cleanFieldValue,
    ...rememberedData,
  }
  const needPrevalidation = Object.values(initialFieldValue).join('') !== Object.values(cleanFieldValue).join('')
  const { submit } = useSubmitMessage(license, sendSingleMessage, 'messageQueue', false, { ...cleanFieldValue })

  const formSchema = computed(() => [
    {
      label: i18n.t('senderName'),
      name: 'sender_name',
      as: 'select',
      type: 'text',
      rules: '',
      cols: 'col-12',
      inputClass: 'form-control',
      options: senderNames || [],
      fieldClass: 'form-group',
      value: '',
      children: generateSenderName(senderNames),
    },
    {
      label: i18n.t('text'),
      name: 'text',
      as: 'textarea',
      type: '',
      rules: 'required',
      validateOnMount: needPrevalidation,
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
    },
    {
      label: i18n.t('phoneOrEmail', 1),
      name: 'recipient',
      as: 'input',
      type: 'text',
      rules: 'required|phone_email',
      validateOnMount: needPrevalidation,
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
    },
    {
      label: i18n.t('mediaURL'),
      name: 'media_url',
      as: 'input',
      type: 'text',
      rules: 'media_url',
      validateOnMount: needPrevalidation,
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
    },
    {
      htmlLabel: i18n.t('termsRulesAgreement'),
      name: 'agreement',
      as: 'input',
      type: 'checkbox',
      rules: 'required',
      validateOnMount: true,
      cols: 'col-12',
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary d-inline',
      value: true,
      unckeckedValue: false,
    },
    // {
    //   label: i18n.t('sendSandboxMessages'),
    //   name: 'sandbox',
    //   as: 'input',
    //   type: 'checkbox',
    //   rules: '',
    //   cols: 'col-12',
    //   labelClass: 'cr',
    //   fieldClass: 'checkbox checkbox-primary d-inline',
    //   value: false,
    //   unckeckedValue: false,
    // },
    // {
    //   label: i18n.t('marketing'),
    //   name: 'marketing',
    //   as: 'input',
    //   type: 'checkbox',
    //   rules: '',
    //   cols: 'col-12',
    //   labelClass: 'cr',
    //   fieldClass: 'checkbox checkbox-primary d-inline',
    //   value: false,
    //   disabled: false,
    //   unckeckedValue: false,
    // },
  ])
  const submitButton = computed(() => ({
    wrapper: 'juatify-content-start',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('send'),
  }))

  const resetButton = computed(() => ({
    wrapper: 'd-flex flex-row justify-content-end',
    class: 'btn btn-outline-danger shadow-2 mt-4',
    text: i18n.t('reset'),
    clearStorage: () => {
      store.dispatch('clearFormData', { license, type: 'SINGLE' })
    },
  }))

  return {
    formSchema,
    submitButton,
    resetButton,
    submit,
    cleanFieldValue,
    initialFieldValue,
    needPrevalidation,
    bulkStatuses: BulkStatuses,
    onChangeHandler: (code, data) => {
      store.dispatch('saveSingleFormData', { license, data })
    },
  }
}
