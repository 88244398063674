import { useI18n } from 'vue-i18n'

export function useGenerateSenderName() {
  const i18 = useI18n()
  const generateSenderName = (names) => {
    const array = [{
      tag: 'option',
      value: '',
      text: i18.t('default_sender'),
    }]
    if (names !== undefined) {
      names.forEach(name => {
        array.push({
          tag: 'option',
          value: name,
          text: name,
        })
      })
    }
    return array
  }
  return {
    generateSenderName,
  }
}
